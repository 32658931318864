import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    userLastAdhesion: null,
  },
  mutations: {
    setUserLastAdhesion(state, userLastAdhesion) {
      state.userLastAdhesion = userLastAdhesion;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
