import { createRouter, createWebHashHistory } from 'vue-router'

const BorrowingPanel = () => import( '../views/borrowing/create-borrowing.page.vue')
const InscriptionPanel = () => import( '../views/adhesion-form.page.vue')
const AdminAdhesionPanel = () => import( '../views/admin/adhesion-admin.page.vue')
const CalendarPanel = () => import( '../views/calendar/detailed-calendar.page.vue')
const EventTemplatesPanel = () => import('../views/calendar/event-templates.page.vue')
const MailTemplatesEditorPanel = () => import( '../views/admin/mail-template-editor.page.vue')
const AddressBookPanel = () => import( '../views/admin/address-book.page.vue')
const GroupsEditorPanel = () => import( '../views/admin/keycloak-groups-admin.page.vue')
const WishlistPanel = () => import( '../views/money/wish-list.page.vue')
const CRPanel = () => import( '../views/calendar/cr-list.page.vue')
const MainPanel = () => import( '../views/home-dashboard.page.vue')
const CalendarEventDisplayMessage = () => import( '../views/calendar/dialogs/event-display.dialog.vue')
const BorrowingReturnPanel = () => import( '../views/borrowing/return-borrowing.page.vue')
const BorrowingScopePanel = () => import( '../views/borrowing/current-borrowings.page.vue')


const BudgetSummary = () => import( '../views/money/budget-summary.page.vue')
const ExpenseAndDocuments = () => import( '../views/money/expenses-documents.page.vue')
const TresoPanel = () => import( '../views/money/finances-management.page.vue')

const UserParametersPanel = () => import( '../views/me/user-parameters.page.vue')
const UserSkillsPanel = () => import( '../views/me/user-skills.page.vue')

const SkillAdminListPanel = () => import( '../views/admin/skill-admin.page.vue')

const WorkshopPricingSimulator = () => import( '../views/workshop-pricing-simulator.page.vue')
const DoorbellAdminPanel = () => import( '../views/admin/doorbell-admin.page.vue')

const ArchiveAdminPanel = () => import('../views/admin/archive-exporter.page.vue')

export default function router(keycloak) {
  function checkLogin(to) {
    if (!keycloak.authenticated) {
      keycloak.login({redirectUri: window.location.origin+ '/#' + to.fullPath});
    }
  }

const routes = [
  {
    path: '/:catchAll(.*)',
    name: 'MainPanel',
    component: MainPanel,
    meta: {
      title: 'En bref'
    }
  },
  {
    path: '/calendrier-club:catchAll(.*)',
    name: 'CalendarPanel',
    component: CalendarPanel,
    meta: {
      title: 'Calendrier du club'
    }
  },
  {
    path: '/event-templates:catchAll(.*)',
    name: 'EventTemplatesPanel',
    component: EventTemplatesPanel,
    meta: {
      title: 'Templates d\'évènements'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/calendrier-club/event/:id/:catchAll(.*)',
    name: 'EventPanel',
    component: CalendarEventDisplayMessage,
    meta: {
      title: 'Evenement'
    },
  },
  {
    path: '/liste-cr:catchAll(.*)',
    name: 'CRPanel',
    component: CRPanel,
    meta: {
      title: 'Liste des CR'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/suivi-budget:catchAll(.*)',
    name: 'BudgetSummary',
    component: BudgetSummary,
    meta: {
      title: 'Suivi du budget'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/emprunts:catchAll(.*)',
    name: 'BorrowingPanel',
    component: BorrowingPanel,
    meta: {
      title: 'Emprunter au club'
    }
  },
  {
    path: '/emprunts/retours:catchAll(.*)',
    name: 'BorrowingReturnPanel',
    component: BorrowingReturnPanel,
    meta: {
      title: 'Rendre un emprunt'
    }
  },
  {
    path: '/emprunts/visualiser:catchAll(.*)',
    name: 'BorrowingScopePanel',
    component: BorrowingScopePanel,
    meta: {
      title: 'Voir les emprunts en cours'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/suggestions-achats:catchAll(.*)',
    name: 'WishlistPanel',
    component: WishlistPanel,
    meta: {
      title: 'Demandes dachats au bureau'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/adhesion-club:catchAll(.*)',
    name: 'InscriptionPanel',
    component: InscriptionPanel,
    meta: {
      title: 'Adhésion au club'
    }
  },
  {
    path: '/admin/adhesion-club:catchAll(.*)',
    name: 'AdminAdhesionPanel',
    component: AdminAdhesionPanel,
    meta: {
      title: 'Gestion des adhésion au club'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/admin/mail-templates-editor:catchAll(.*)',
    name: 'MailTemplatesEditorPanel',
    component: MailTemplatesEditorPanel,
    meta: {
      title: 'Edition des templates de mails'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/admin/groups-editor:catchAll(.*)',
    name: 'GroupsEditorPanel',
    component: GroupsEditorPanel,
    meta: {
      title: 'Edition des groupes utilisateurs'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/admin/address-book:catchAll(.*)',
    name: 'AddressBookPanel',
    component: AddressBookPanel,
    meta: {
      title: 'Carnet d\'adresses du bureau'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/me:catchAll(.*)',
    name: 'UserParameters',
    component: UserParametersPanel,
    meta: {
      title: 'Paramètres utilisateur'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/me/skills:catchAll(.*)',
    name: 'UserSkills',
    component: UserSkillsPanel,
    meta: {
      title: 'Mes compétences'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/admin/skills:catchAll(.*)',
    name: 'SkillAdminListPanel',
    component: SkillAdminListPanel,
    meta: {
      title: 'Compétences'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/notes-de-frais:catchAll(.*)',
    name: 'ExpenseAndDocuments',
    component: ExpenseAndDocuments,
    meta: {
      title: 'Note de frais'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/treso:catchAll(.*)',
    name: 'TresoPanel',
    component: TresoPanel,
    meta: {
      title: 'Trésorerie'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/simulateur-de-prix-ateliers:catchAll(.*)',
    name: 'WorkshopPricingSimulator',
    component: WorkshopPricingSimulator,
    meta: {
      title: 'Simulateur de prix des ateliers'
    }
  },
  {
    path: '/admin/doorbell:catchAll(.*)',
    name: 'DoorbellAdminPanel',
    component: DoorbellAdminPanel,
    meta: {
      title: 'Gestion de la sonette du club'
    },
    beforeEnter: [checkLogin]
  },
  {
    path: '/admin/archive:catchAll(.*)',
    name: 'ArchiveAdminPanel',
    component: ArchiveAdminPanel,
    meta: {
      title: 'Archivage de l\'intranet'
    },
    beforeEnter: [checkLogin]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


return router
}
